.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 400px;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.preview {
  padding: 3rem;
  margin-top: 1rem;
  text-align: left;
}
.select {
  align-self: start;
}
.button {
  background-color: "#77BBBB";
}
.spacing {
  margin-left: "70px";
}
.progress {
  color: "#77BBBB";
}

mainDiv {
  padding-top: 80px;
  margin-left: 200px;
  margin-right: 200px;
  padding-bottom: 10px;
}