.mainDiv {
  padding-top: 80px;
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 10px;
}
.grid {
  height: 550px;
  width: auto;
  padding-top: 20px;
}
.boxstyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 50;
  padding: 4px;
}

.modtext {
  display: inline-block;
  font-weight: bold;
  /* padding-top: 10px;
  margin-top: 20px; */
}

.mod {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.button {
  text-align: right;
}
.alert {
  padding-top: 10px;
}
