.loginDiv {
  padding-top: 90px;
}
.grid {
  height: 550px;
  width: auto;
  padding-top: 20px;
}
.loginBox {
  top: 50%;
  left: 50%;
  width: 400px;
  background-color: white;
  border: 1px solid #49aef4;
  border-radius: 10px;
  padding: 20px;
}

.modtext {
  display: inline-block;
  /* padding-top: 10px; */
}

.mod {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.button {
  text-align: right;
}
.textbox {
  padding: "10px";
  padding-bottom: "50px";
}
