div.footer {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  /* background-color: #000000; */
  color: #ffffff;
  /* margin-top: 30px; */
}

.footer ul {
  list-style-type: none;
}
.footer li img {
  max-width: 30px;
}
.icon {
  display: inline-block;
}
.copyright {
  border-top: white 2px solid;
}
.copyright p {
  margin: auto;
  padding: 10px;
}
@media (max-width: 768px) {
  .footer {
    padding-bottom: 30px;
  }
}
