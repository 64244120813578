.root {
  display: flex;
  flex-grow: 1;
}
.menuButton {
  margin-right: 10px;
}
.hide {
  display: none;
}
